@import url(./app/assets/fonts/monument_grotesk/stylesheet.css);
@import url(./app/assets/fonts/neutra_text/stylesheet.css);
@import url(./app/assets/fonts/trade_gothic/stylesheet.css);
@import url(./app/assets/fonts/silka/stylesheet.css);

html, body {
  overscroll-behavior: none;
}

.no-scroll {
  margin: 0; height: 100%; overflow: hidden;
}

body {
  margin: 0;
  font-family: var(--typography);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:has(dialog[open]) {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (hover: none) {
  *:hover {
    transition: none;
    animation: none;
  }
}

.modal-toastify-body{
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background-color: var(--backdrop_color);
   display: flex;
   align-items: flex-end;
   justify-content: center;
   overflow: hidden;
   z-index: 99999;
   padding: 40px 20px 0px;
}

.modal-toastify-in {
   animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards!important;
   } @keyframes fadeIn {
     0% {
       background-color:transparent;
     }
     100% {
       background-color:var(--backdrop_color);
     }
 }
 
 .modal-toastify-out{
   animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
   } @keyframes fadeOut {  
     0% {
       background-color:var(--backdrop_color);
     }
     100% {
       background-color:transparent;
     }
 }

/* Google Places dropdown */

body > .pac-container {
  display: none;
}

.pac-container {
  background-color: var(--background_rised);
  border: 2px solid var(--input_border);
  border-radius: 0 0 var(--radius_sm) var(--radius_sm);
  padding: 8px 12px;
  margin: -2px auto;
  box-shadow: var(--shadow_dropdown);
  z-index: 999999999 !important;
  position: static !important;
  
  
}

.pac-icon {
  display: none;
}

.pac-item {
  font-family: var(--typography);
  color: var(--text_a50);
  padding: 2px;
}

.pac-item:first-child {
  border: none;
}

.pac-item-selected {
  background-color: var(--primary_a10);
}

.pac-item-query {
  color: var(--text_a80);
  font-weight: 500;
}

.pac-matched {
  color: var(--text);
  font-weight: 600;
}